import React, { useState, useEffect } from "react";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GroupChannelHandler, HiddenChannelFilter } from '@sendbird/chat/groupChannel';
import { SENDBIRD_INFO } from "../../../utils/helpers/constant";
import { toastError } from "../../../utils/helpers/methods";
import Spinner from "../spinner";
import ChanneLItemPreview from "./chatItemPreview";
import { getMessageCount ,getAllMessage,getAssignedMessage} from "../../../redux/slices/notifications";
import ChatPagination from "../../ChatPagination";
import ChatHistory from "./chatHistory";

const limit = 100;

const ChanneListPreview = ({handleClose, activeTab, currentUser, handleChatView}) => {
  const store = useSendbirdStateContext();
  var sdk = store?.stores.sdkStore.sdk;
  const { doctorDetails } = useSelector((state) => state.auth)
  const [searchQuery, setSearchQuery] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [archiveChannelList, setArchiveChannelList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  useEffect(() => {
    if (!sdk || !sdk.groupChannel) {
      return
    }
    activeTab === "tab2" ? loadArchiveChannels() : fetchChannels();
  }, [activeTab, sdk]);

  useEffect(() => {
    getChannel();
  }, [location]);

  useEffect(() => {
    activeTab === "tab0" && dispatch(getMessageCount(channelList));
    dispatch(getAllMessage(channelList));
    const assignedMessage =channelList && channelList.length > 0 && channelList.filter((x) => x.data && x.data === doctorDetails.data.doctor_login.sendbird_user_id);
    dispatch(getAssignedMessage(assignedMessage));
  },[channelList])

  const getChannel = async () => {
    let sendbird = sdk;
    const channelHandler = new GroupChannelHandler({
      onChannelHidden: (channel) => {
        activeTab === "tab2" ? setArchiveChannelList([]) : setChannelList([]);
        activeTab === "tab2" ? loadArchiveChannels() : fetchChannels();
      }, 
      onChannelChanged: (channel) => {
        activeTab === "tab2" ? setArchiveChannelList([]) : setChannelList([]);
        activeTab === "tab2" ? loadArchiveChannels() : fetchChannels();
      },
    });
    var handlerID = "channelList_care";

    sendbird.groupChannel.addGroupChannelHandler(handlerID, channelHandler);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchButtonClick = () => {
    setSearchQuery(searchQuery.trimStart());
    if (searchQuery.trimStart().length > 0) {
      activeTab === 'tab2' ? loadArchiveChannels(searchQuery) : fetchChannels(searchQuery);
    } else {
      setSearchQuery("");
      activeTab === "tab2" ? loadArchiveChannels() : fetchChannels();
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    setChannelList([]);
    fetchChannels();
    handleClose();
  }

  const loadArchiveChannels = async (searchKey = null) => {
    setLoading(true);
    try {
      let sendbird = sdk;
      let params = {
        limit: limit,
        hiddenChannelFilter: HiddenChannelFilter.HIDDEN,
        customTypesFilter:['DPCDoctor','Teams','Provider','Nonprovider'],
      };
      if (searchKey && searchKey.length > 0) {
        params.nicknameContainsFilter = searchKey
      }
      const query = sendbird.groupChannel.createMyGroupChannelListQuery(params);
      if (query.hasNext) {
        const channels = await query.next();
        setQuery(query);
        setHasNext(query.hasNext);
        if (channels.length > 0) {
          setArchiveChannelList(channels);
          setLoading(false);
        } else {
          setArchiveChannelList([]);
          setLoading(false);
        }
      } else {
        setArchiveChannelList([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return [null, error];
    }
  }

  const fetchChannels = async (searchKey = null) => {
    setLoading(true);
    try {
    let sendbird = sdk;
    let params = {
      limit: limit,
      order: "latest_last_message",
      customTypesFilter:['DPCDoctor','Teams','Provider','Nonprovider'],
    };
    if (searchKey && searchKey.length > 0) {
      params.nicknameContainsFilter = searchKey;
    }
    let query = sendbird.groupChannel.createMyGroupChannelListQuery(params);
    if (query.hasNext) {
      const fetchedChannels = await query.next();
      setQuery(query);
      setHasNext(query.hasNext);
      if (fetchedChannels.length > 0) {
        setChannelList(fetchedChannels);
        setLoading(false);
      } else {
        setChannelList([]);
        setLoading(false);
      }
    } else {
      setChannelList([]);
      setLoading(false);
    }
    } catch (error) {
      setLoading(false);
      return [null, error];
    }
  };

  const onChangePage = async (page) => {
    setCurrentPage(page);
    if(currentPage < page && query.hasNext) {
      setLoading(true);
      var nextQuery = query
      if (nextQuery.hasNext) {
        var fetchedChannels = await nextQuery.next();
        if (fetchedChannels.length > 0) {
          if(activeTab === 'tab0' || activeTab === 'tab1'){
            setChannelList([...channelList, ...fetchedChannels]);
          } else {
            setArchiveChannelList([...archiveChannelList, ...fetchedChannels])
          }
          setQuery(nextQuery);
          setLoading(false);
          setHasNext(query.hasNext);
        }
        else {
          setHasNext(false);
          setLoading(false)
        }
      } else {
        setLoading(false);
      }
    } else if(currentPage > page) {
      setHasNext(true);
    } else if(((activeTab === 'tab0' || activeTab === 'tab1') && (page*limit) === channelList.length) || (activeTab === 'tab2' && ((page*limit) === archiveChannelList.length))) {
      setHasNext(false);
    } else{
      setHasNext(true);
    }
  };
  

  const moveToPractice = async (item) => {
    setLoading(true);
    var dataParams = { data: "" };
    const hideList = item.members.filter(x=>x.userId!==doctorDetails.data.doctor_login.sendbird_user_id && !(x.userId.startsWith("PA")));
    fetch(
      `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${item.url}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Api-Token": SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify(dataParams),
      }
    )
      .then((updatedChannel) => {
        if (updatedChannel !== "" && hideList && hideList.length) {
          hideList.map((x)=>{
            return Promise.all([
            fetch(
              `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${item.url}/hide`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  "Api-Token": SENDBIRD_INFO.sendBirdToken,
                },
                body: JSON.stringify({ "user_id": x.userId, "should_unhide_all": true }),
              }
            ),
          ]).then((res) => {
            setLoading(false);
            handleUpdate();
          });
        });
        } 
        else {
          setLoading(false);
          toastError("Couldn't Move to Practice")
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, "error move to");
        toastError("Something went wrong")
      });
  };

  const renderListItem = () => {
    const index = (currentPage-1)*limit;
    const itemList = activeTab === 'tab0' || activeTab === 'tab1' ? [...channelList] : [...archiveChannelList];
    const spliceList = itemList.splice(index, limit);
    const filterList = activeTab === 'tab0' && spliceList && spliceList.length > 0 ? spliceList.filter((x) => x.data === "" || x.data !== doctorDetails.data.doctor_login.sendbird_user_id) : 
    activeTab === 'tab1' && spliceList && spliceList.length > 0 ? spliceList.filter((x) => x.data && x.data === doctorDetails.data.doctor_login.sendbird_user_id) : spliceList;

    return ( filterList && filterList.length > 0 && !loading ? (filterList.map((item, index) => {return(
        <ChanneLItemPreview isMoveToPractice={activeTab === 'tab1'} moveToPractice={(item)=>moveToPractice(item)} item={item} key={index} onClick={handleChatView} currentUser={currentUser}/>
      )})
      ) : !loading && activeTab !== "tab3" ? (
        <p className="flex-1 w-full h-1/2 flex justify-center items-center text-base text-gray-500">
          {activeTab === "tab2" ? "No Archived Conversation" : "No Conversation"}
        </p>) : null)
  }

  return (
    <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      {((activeTab === "tab0" && channelList && channelList.length > 0 && channelList.filter((x) => x.data === "" || x.data !== doctorDetails.data.doctor_login.sendbird_user_id).length > 0 && !loading) ||
         (activeTab === "tab1" && (channelList && channelList.length > 0 && channelList.filter((x) => x.data && x.data === doctorDetails.data.doctor_login.sendbird_user_id).length > 0) && !loading) || 
         (activeTab === 'tab2' && archiveChannelList && archiveChannelList.length > 0 && !loading)) &&
          <div className="relative">
            <input
              type="text"
              placeholder={activeTab === 'tab1' ? "Search patients" : "Search patients and provider"}
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearchButtonClick();
                }
              }}
              className="w-full py-2 px-2 rounded-lg border border-gray-300 pr-24 focus:border-everglade-500 focus:outline-none focus:ring-everglade-500"
            />
            <button
              onClick={handleSearchButtonClick}
              className="absolute top-1/2 right-[0.1rem] transform translate-y-[-50%] p-2 bg-everglade-800 rounded-lg hover:bg-everglade-500"
            >
                <Icon icon={"icon-park-outline:find"} className="text-white" />
            </button>
          </div>}
          {activeTab === "tab3" && <ChatHistory/>}
        <div className="flow-root p-3">
            {activeTab === "tab2" && archiveChannelList && archiveChannelList.length > 0 && !loading ? renderListItem()
              : activeTab === "tab1" && channelList && channelList.length > 0 && !loading ? renderListItem() 
              : activeTab === "tab0" && channelList && channelList.length > 0 && !loading ? renderListItem()
              : loading ? (
              <section className="flex-1 w-full h-1/2 flex justify-center items-center">
                <Spinner />
              </section>
              ) : !loading&& activeTab !== "tab3" ? (
              <p className="flex-1 w-full h-1/2 flex justify-center items-center text-base text-gray-500">
                {activeTab === "tab2" ? "No Archived Conversation" : "No Conversation"}
              </p>
            ) : null}
        </div>
        <div className='my-3 flex  justify-center pr-4 w-full md:justify-end'>
        {activeTab === "tab0" && channelList && channelList.length > 0 && channelList.filter((x) => x.data === "" || x.data !== doctorDetails.data.doctor_login.sendbird_user_id).length > 0 && !loading ? 
          <ChatPagination hasNext={hasNext} page={currentPage} onChange={(page)=> onChangePage(page)} /> : 
        activeTab === "tab1" && (channelList && channelList.length > 0 && channelList.filter((x) => x.data && x.data === doctorDetails.data.doctor_login.sendbird_user_id).length > 0) && !loading ? 
          <ChatPagination hasNext={hasNext} page={currentPage} onChange={(page)=> onChangePage(page)} /> :
        activeTab === "tab2" && archiveChannelList && archiveChannelList.length > 0 && !loading ? 
          <ChatPagination hasNext={hasNext} page={currentPage} onChange={(page)=> onChangePage(page)}/> 
        : null}
      </div>
    </div>
  );
};

export default ChanneListPreview;

import { Icon } from '@iconify/react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { PatientListfilter, getPatientList } from '../redux/actions/passActions'
import Pagination from './pagination'
import Avatar from './shared/chat/avatar'
import Table from './shared/table'
import { getfilterData, togglePatientDetail } from '../redux/slices/filter'
import { Badge, Dropdown, Label, Select, Spinner } from 'flowbite-react'
import { Menu, Listbox } from '@headlessui/react'
import { backendURL } from '../config/api'

const PatientList = () => {
  const { userToken, userInfo } = useSelector((state) => state.auth)
  const { filterData, ispatientDetail } = useSelector((state) => state.filter)
  const { PatientListinfo, PatientList, Patientfilter } = useSelector(
    (state) => state.pass
  )
  const [selectedItemName, setSelectedItemName] = useState('')
  const [selectedGroupName, setSelectedGroupName] = useState('')
  let patientUrl = `${backendURL}/img/patient`
  const dispatch = useDispatch()
  const [PatientFilter, setPatientFilter] = useState([])
  const [PatientFilterval, setPatientFilterval] = useState({
    group: '',
    tag: '',
    search: '',
    patient_login: '',
    dob:'',
    status:''
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [PatientList])

  const [currentPage, setCurrentPage] = useState(1)
  const location = useLocation()

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: selectedGroupName,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search,
      page: pageNumber,
      patient_login: PatientFilterval.patient_login,
      dob: PatientFilterval.dob,
      status:PatientFilterval.status
    }
    dispatch(getPatientList(obj))
  }

  useEffect(() => {
    //   dispatch(getListPasswords({ token: userToken }));
    const data = location?.state
    if (ispatientDetail) {
      setPatientFilterval({ ...filterData })
    }
    if(data && data.option){
      setPatientFilterval({ ...PatientFilterval, patient_login: data.option })
      let obj = {
        token: userToken,
        practitioner_id: userInfo.id,
        employer_filter: "",
        patient_tag: "",
        limit: 5,
        patient_login: data.option,
        dob: PatientFilterval.dob,
        status:PatientFilterval.status
      }
      dispatch(getPatientList(obj))
    } else if(data && data.search_key) {
      setPatientFilterval({ ...PatientFilterval, search: data.search_key })
      let obj = {
        token: userToken,
        practitioner_id: userInfo.id,
        employer_filter: "",
        patient_tag: "",
        search_key: data.search_key,
        limit: 5,
        patient_login: PatientFilterval.patient_login,
        dob: PatientFilterval.dob,
        status:PatientFilterval.status      }
      dispatch(getPatientList(obj))
    } else {
      // setPatientFilterval({ ...PatientFilterval, search: data });
      let obj = {
        token: userToken,
        practitioner_id: userInfo.id,
        employer_filter: '',
        patient_tag: '',
        search_key: data ? data : '',
        limit: 5,
        patient_login: PatientFilterval.patient_login,
        dob: PatientFilterval.dob,
        status:PatientFilterval.status      }
      dispatch(getPatientList(obj))
    }
    dispatch(
      PatientListfilter({
        practitioner_id: userInfo.id,
        token: userToken,
      })
    )

    setTimeout(() => {
      dispatch(togglePatientDetail(false))
      if (PatientListinfo && PatientListinfo.data) {
        setPatientFilter(Patientfilter)
      }
    }, 500)
    //eslint-disable-next-line
  }, [])
  const handleChangetag = (event) => {
    console.log({event:event.target.value})
    setPatientFilterval({ ...PatientFilterval, tag: event.target.value })

    // make API call with selectedOption
  }
  const handleChangegroup = (event) => {
    setPatientFilterval({ ...PatientFilterval, group: event.target.value })
    // make API call with selectedOption
  }
  const handleChangesearch = (event) => {
    setPatientFilterval({ ...PatientFilterval, search: event.target.value })
    // make API call with selectedOption
  }
  const handleDOBChanges=(event)=>{
    const inputDate = event.target.value; 
    if (inputDate) {
      const [year, month, day] = inputDate.split('-');
      setPatientFilterval({ ...PatientFilterval, dob: `${month}/${day}/${year}` })
    } else {
      setPatientFilterval({ ...PatientFilterval, dob: event.target.value })
    }
  }
  const handleStatusChanges=(event)=>{
     setPatientFilterval({ ...PatientFilterval, status: event.target.value })
  }
  const FilterType = (e) => {
    setPatientFilterval({ ...PatientFilterval, patient_login: e })
    return
  }

  const handlefilter = () => {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: selectedGroupName,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search,
      patient_login: PatientFilterval.patient_login,
      dob:PatientFilterval.dob,
      status:PatientFilterval.status
    }
    dispatch(getPatientList(obj))
    dispatch(getfilterData(PatientFilterval))
    // setPatihandleGroupChangeentList([])
    // setTimeout(() => {
    //   (PatientListinfo);
    //   setPatientList(PatientListinfo.data.patients)
    // }, 2000);
  }


  const columns = [
    {
      header: '',
      cell: (row) => (
        <Avatar
          fname={!row.address.profile_image && row.address.first_name}
          lname={!row.address.profile_image && row.address.last_name}
          image={
            row.address.profile_image
              ? `${patientUrl}/${row.address.profile_image}`
              : ''
          }
          className={' hover:cursor-pointer'}
          showStatus={false}
        />
      ),
    },
    {
      header: 'Name',
      cell: (row) => <div className='flex flex-row'>{row?.address?.first_name+ ' '+row?.address?.last_name} {row?.is_vip==1 && <Icon className="ml-2 mb-2"  icon="quill:vip" fontSize={30} color='#ffbf00'></Icon>}</div>,
    },
    {
      header: 'DOB',
      cell: (row) => <>{row?.address?.dob}</>,
    },
    {
      header: 'Status',
      cell: (row) => <div className='flex flex-row'>  <Badge color={(row?.deleted_at==''||row?.deleted_at==null)?'success':'failure'}>{(row?.deleted_at==null||row?.deleted_at=='')?'Active':'InActive'}</Badge></div>,
    },
    {
      header: 'Current Employer',
      cell: (row) => <div className='flex flex-row'>  {row?.location_group?.group_name&&<Badge color="info">{row?.location_group?.group_name}</Badge>}</div>,
    },
    
    {
      header: 'Relationship',
      cell: (row) => <>{row?.address?.relationship}</>,
    },
    {
      header: '',
      cell: (row) => (
        <div className='text-sm text-everglade-800'>
          <Link
            to={`/patientDetails/${row.id}`}
            className='hover:bg-everglade-800 hover:text-white font-bold py-2 px-3 mx-2  rounded'
            onClick={viewDetail}
          >
            View Details
          </Link>
        </div>
      ),
    },
  ]
  const viewDetail = () => {
    dispatch(togglePatientDetail(true))
  }

  function Filters(id) {
    switch (id) {
      case 1:
        return 'Available'
      case 2:
        return 'Unvailable'
      default:
        return 'All'
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const tagDropDownRef = useRef(null)
  const [isGroupOpen, setIsGroupOpen] = useState(false)
  const groupDropDownRef = useRef(null)



  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }
  const toggleGroupDropdown = () => {
    setIsGroupOpen((prev) => !prev)
  }

  const handleChange = (value) => {
    setPatientFilterval({ ...PatientFilterval, tag: value.toString() })
    setSelectedItemName(Patientfilter.patient_tags[value]?.tag_name)
    setIsOpen(false)
  }
  const handleGroupChange = (value) => {
    setPatientFilterval({ ...PatientFilterval, group: value })
    setSelectedGroupName(Patientfilter?.groups[value])
    setIsGroupOpen(false)
  }

  const handleTagClickOutside = (event) => {
    if (
      tagDropDownRef.current &&
      !tagDropDownRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }
  const handleGroupClickOutside = (event) => {
    if (
      groupDropDownRef.current &&
      !groupDropDownRef.current.contains(event.target)
    ) {
      setIsGroupOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleTagClickOutside)
    document.addEventListener('click', handleGroupClickOutside)

    return () => {
      document.removeEventListener('click', handleTagClickOutside)
      document.removeEventListener('click', handleGroupClickOutside)

    }
  }, [])


  return (
    <div className='w-full'>
      <div className='w-full overflow-x-hidden mt-[30px]  pt-4'>
        <div className=' mx-auto'>
          <section className='w-full'>
            <Table
              loader={isLoading}
              count={0}
              defaultPage={1}
              page={currentPage}
              onChange={handlePageChange}
              totalPages={PatientListinfo?.data?.total_pages}
              columns={columns}
              data={PatientList?.slice(0, 8)}
              header={() => {
                return (
                  <section className='w-full  grid grid-cols-1 lg:grid-cols-1 gap-3 justify-between items-center'>
                    <h1 className='text-base w-[100px] font-semibold text-everglade-900 pl-2 shrink-0 '>
                      Patient List
                    </h1>
                    <div
                      className={`w-full px-10 lg:px-0 flex col-span-3 flex-wrap  gap-2  items-center`}
                    >
                      {/* <button className='w-[100px] h-[35px] bg-everglade-800 rounded-full flex flex-row justify-center items-center gap-2'>
                    <Icon icon='ic:round-plus' color='#ffbe0b' fontSize={18} />
                    <p className='text-white'>New</p>
                    </button> */}
                      {
                        <Listbox
                          value={PatientFilterval.patient_login}
                          onChange={FilterType}
                        >
                          <Listbox.Button className='w-[130px] h-[35px] bg-everglade-800 rounded-full flex flex-row justify-center items-center gap-2'>
                            <Icon
                              icon='ic:round-plus'
                              color='#ffbe0b'
                              fontSize={18}
                            />
                            <p className='text-white'>
                              {Filters(PatientFilterval.patient_login)}
                            </p>
                          </Listbox.Button>
                          <Listbox.Options className='absolute z-10 w-[120px] p-1 mt-[125px] bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none '>
                            <Listbox.Option
                              value={''}
                              className={
                                'hover:bg-everglade-500 hover:text-white p-2 '
                              }
                            >
                              All
                            </Listbox.Option>
                            <Listbox.Option
                              value={1}
                              className={
                                'hover:bg-everglade-500 hover:text-white p-2'
                              }
                            >
                              Available
                            </Listbox.Option>
                            <Listbox.Option
                              value={2}
                              className={
                                'hover:bg-everglade-500 hover:text-white p-2'
                              }
                            >
                              Unavailable
                            </Listbox.Option>
                          </Listbox.Options>
                        </Listbox>
                      }
                      
                      {Patientfilter && (
                        <div className='w-[230px]'>
                        <div className='relative w-full' ref={tagDropDownRef}>
                          <div
                            className='w-full bg-white border rounded p-2 cursor-pointer'
                            onClick={toggleDropdown}
                          >
                            {selectedItemName|| 'Patient Tag'}
                          </div>
                          {isOpen && (
                            <div
                              className='absolute w-full  bg-white border rounded border-gray-300 shadow-md z-10  top-full'
                              style={{ top: 'auto', marginTop: '2px' }}
                            >
                              {Patientfilter.patient_tags.map((item,idx) => (
                                <div
                                  key={item.id}
                                  className='p-2 cursor-pointer flex justify-between items-center hover:bg-everglade-500 hover:text-white'
                                  onClick={() => handleChange(idx)}
                                >
                                  {item.tag_name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      )}
                      {Patientfilter && (
                        <div className='w-[350px]'>
                        <div className='relative w-full' ref={groupDropDownRef}>
                          <div
                            className='w-full bg-white border rounded p-2 cursor-pointer'
                            onClick={toggleGroupDropdown}
                          >
                            {selectedGroupName|| 'Group'}
                          </div>
                          {isGroupOpen && (
                            <div
                              className='absolute w-full h-[300px] overflow-y-auto  bg-white border rounded border-gray-300 shadow-md z-10  top-full'
                              style={{ top: 'auto', marginTop: '2px' }}
                            >
                              {Object.entries(Patientfilter.groups).map(([key,value],idx)=>{
                                if(value){
                                return(
                                  <>
                                  <div
                                  key={key}
                                  className='p-2 cursor-pointer hover:bg-everglade-500 hover:text-white flex justify-between items-center'
                                  onClick={() => handleGroupChange(key)}
                                >
                                  {value}
                                </div>
                                  </>
                                )
                                }
                              })}
          
                            </div>
                          )}
                        </div>
                      </div>
                      )}
                      {Patientfilter && (
                        <div className='relative shrink-0 '>
                          <input
                            value={PatientFilterval.search}
                            onChange={handleChangesearch}
                            className='shrink-0 relative h-[40px] rounded-md border-[0.5px]  focus:outline-none focus:border-everglade-800 pl-[40px]  focus:ring-everglade-800'
                            placeholder='Search'
                          />
                          <Icon
                            icon='ic:sharp-search'
                            fontSize={24}
                            className='absolute top-2 left-2 text-everglade-800 '
                          />
                        </div>
                      )}{' '}
                       {Patientfilter && (
                        <div className='relative shrink-0 '>
                          <input type='date' 
                            onChange={handleDOBChanges}
                            className="border-gray-300 hover:border-everglade-400 focus:border-everglade-600 focus:ring focus:ring-everglade-200 focus:ring-opacity-50 rounded-md p-2"
                            placeholder='DOB'
                            name='DOB'
                          />
                        </div>
                      )}{' '}
                      <div className="max-w-md">

                        <select
                          id="ststus"
                          onClick={handleStatusChanges}
                          className="border-gray-300 hover:border-everglade-400 focus:border-everglade-600 focus:ring focus:ring-everglade-200 focus:ring-opacity-50 rounded-md p-2"
                        >
                           <option value="">All</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                      {Patientfilter && (
                        <button
                          onClick={handlefilter}
                          className=' p-2 w-[150px] h-[35px] bg-everglade-800 hover:bg-everglade-400 rounded-md flex items-center justify-center'
                        >
                          <p className='text-white'>Submit</p>
                        </button>
                      )}
                    </div>
                  </section>
                )
              }}
            />
            {PatientList?.length > 0 && (
              <div className='my-3 flex  justify-center pr-4 w-full md:justify-end'>
                {PatientListinfo?.data?.total_pages > 0 && !isLoading && (
                  <Pagination
                    count={10}
                    defaultPage={1}
                    page={currentPage}
                    onChange={handlePageChange}
                    totalPages={PatientListinfo?.data?.total_pages}
                    totalCount={PatientListinfo?.data.total_count}
                  />
                )}
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  )
}

export default PatientList

import { Menu } from '@headlessui/react'
import { SendingStatus } from '@sendbird/chat/message'
import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import {
  FaFileAlt,
  FaFileExcel,
  FaFilePdf,
  FaFileWord,
  FaPhoneAlt,
} from 'react-icons/fa'
import { HiDotsVertical } from 'react-icons/hi'
import { LiaCheckDoubleSolid } from 'react-icons/lia'
import { LuForward } from 'react-icons/lu'
import { MdOutlineCheck } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { getFileExtension } from '../input/fileUtils'
import Avatar from './avatar'

export const CustomChat = ({
  user,
  message,
  channel,
  formatMessageTime,
  setMessage,
  setMessageForwardedFrom,
  addToMyChat,
  deleteMyChat,
}) => {
  const { currentGroupChannel } = useChannelContext()
  const { doctorDetails } = useSelector((state) => state.auth)

  const [read, setRead] = useState(false)
  const [delivered, setDelivered] = useState(false)
  const [viewImage, setViewImage] = useState(false)
  const [messageData, setMessageData] = useState()
  const { message: Message } = message

  useEffect(() => {
    if (message?.message?.data) {
      const data = JSON.parse(message.message.data)
      setMessageData(data)
    }
    if (
      Message.sendingStatus === SendingStatus.SUCCEEDED &&
      currentGroupChannel?.getUnreadMemberCount?.(message) === 0
    ) {
      setRead(true)
    }
    if (
      Message.sendingStatus === SendingStatus.SUCCEEDED &&
      currentGroupChannel?.getUndeliveredMemberCount?.(message) === 0
    ) {
      setDelivered(true)
    }
  }, [])

  const SaveFile = (fileUrl, fileName) => {
    saveAs(fileUrl, fileName)
  }

  function saveImage(url) {
    saveAs(url, 'img')
  }

  window.handleDownload = (url) => {
    return saveImage(url)
  }

  const createMarkup = (text) => {
    return { __html: text }
  }

  const messageFormat = (message, color) => {
    if (!message) return null
    const urlRegex = /(https?:\/\/[^\s]+)/g

    const replaceURLsWithLinks = (text) => {
      return text.replace(urlRegex, (url) => {
        const decodedURL = decodeURIComponent(url)

        if (/\.(jpg|jpeg|png)$/i.test(decodedURL)) {
          return `<img src="${decodedURL}" alt="file"   onclick="return handleDownload('${decodedURL}')" alt="file"/>`
        }
        if (/\.(docx|pdf|xls)$/i.test(decodedURL)) {
          return `<a href="${decodedURL}" alt="file" target="download" >  <p class="${color
              ? ' bg-everglade-600  text-white  '
              : ' bg-[#F5F5F5] text-black'
            } rounded-md mt-2 p-2 text-center">   Download  </p></a>
         `
        } else {
          return `<a href="${decodedURL}" class="underline"> ${decodedURL?.length > 70
              ? decodedURL.slice(0, 90) + '....'
              : decodedURL
            } </a>`
        }
      })
    }
    return (
      <div
        className={`${color ? ' p-2 text-black' : ' text-white'
          } rounded-md max-w-[550px]`}
      >
        <span
          dangerouslySetInnerHTML={createMarkup(
            replaceURLsWithLinks(message).replace(/\n/g, '<br>')
          )}
        />
      </div>
    )
  }

  const ogMetaDataFormat = (data, color) => {
    return (
      <>
        <div
          className={`${color
              ? ' text-black '
              : ' text-white  '
            } p-2 rounded-md max-w-[550px]`}
        >
          {data.message.ogMetaData && (
            <div
              className={`${color
                  ? ' text-black '
                  : ' text-white  '
                } p-2 flex flex-row justify-between`}
            >
              {data.message.ogMetaData?.defaultImage?.url && (
                <div className='w-40'>
                  <a
                    className={`${color
                        ? 'text-black '
                        : 'bg-everglade-600  text-white  '
                      } rounded-md m-4 max-w-[550px]`}
                    href={data.message.ogMetaData.url}
                  >
                    <img
                      src={data.message.ogMetaData?.defaultImage?.url}
                      alt={data.message.ogMetaData?.title}
                    />
                  </a>
                </div>
              )}
              <div className='flex flex-col justify-evenly '>
                <h1 className='font-extrabold'>
                  {data.message.ogMetaData?.title}
                </h1>
                <p>{data.message.ogMetaData?.description}</p>
                {messageFormat(data?.message?.message, color)}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
  const dislayPredefinedData = (color) => {
    return (
      <div
        className={`${color ? '  text-black ' : ' text-white checkme'
          } flex flex-col max-w-[550px]`}
      >
        {messageData?.file?.map((e, i) => {
          const ext = getFileExtension(e.filename)
          let format = {
            message: {
              url: doctorDetails?.data?.replacement_url + '/' + e.filename,
              name: e.filename,
            },
          }
          return (
            <div className='p-1' id={i}>
              {dislayFiles(format, 'file', ext)}
            </div>
          )
        })}

        {messageData?.url?.length > 0 && (
          <>
            <p className='font-bold'>Link : </p>
            {messageData?.url?.map((e) => {
              return messageFormat(e, color)
              // <a className='underline' href={e}>{' '+ e}</a>
            })}
          </>
        )}
        {/* <p>{messageData?.message}</p> */}
      </div>
    )
  }

  const formatDuration = (stamp) => {
    if (
      stamp.callStatus.toLowerCase() === 'declined' ||
      stamp.callStatus.toLowerCase() === 'missed' ||
      stamp.callStatus.toLowerCase() === 'canceled'
    ) {
      return (
        <div className='flex flex-row gap-2'>
          <p>{stamp.callStatus}</p>
          {stamp?.time ? format(new Date(stamp?.time), 'hh:mm a') : ''}
        </div>
      )
    } else {
      // const durationMillis = parseInt(stamp?.duration)
      // const durationSeconds = Math.round(durationMillis / 1000)

      // const hours = Math.floor(durationSeconds / 3600)
      // const minutes = Math.floor((durationSeconds % 3600) / 60)
      // const seconds = durationSeconds % 60
      const hours = Math.round(stamp?.duration / (1000 * 60 * 60) % 24);
      const minutes = Math.round((stamp.duration / (1000 * 60)) % 60);
      const seconds = Math.round((stamp.duration / 1000) % 60);
      return (
        <div className='flex flex-row'>
          <p>CALL ENDED</p>
          <div className='flex flex-row justify-evenly items-center gap-2'>
            <p className='pl-1'>
              {hours > 0 ? `${hours}h ` : ''}
              {minutes > 0 ? `${minutes}m ` : ''}
              {seconds > 0 ? `${seconds}s` : ''}
            </p>
            {format(new Date(stamp?.time), 'hh:mm a')}
          </div>
        </div>
      )
    }
  }

  const dislayFiles = (message, messageType, type, color) => {
    if (messageType === 'file') {
      switch (type) {
        case 'png':
        case 'jpeg':
        case 'gif':
        case 'jpg':
        case 'bmp':
        case 'tiff':
        case 'webp':
        case 'image/png':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/jpg':
        case 'image/x-ms-bmp':
        case 'image/bmp':
        case 'image/tiff':
        case 'image/svg+xml':
        case 'image/svg':
        case 'svg':
        case 'image/avif':
          return (
            <img
              src={message?.message?.url}
              alt='File'
              className='w-[200px] h-[200px] object-cover hover:cursor-pointer'
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            />
          )
        case 'pdf':
        case 'application/pdf':
          return (
            <div
              className={`flex flex-col ${color
                ? 'text-white'
                : 'text-black'
                }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            >
              <div
                className={`${color
                    ? 'text-white'
                    : 'text-black'
                  } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFilePdf
                  size={30}
                  className={` ${color ? 'text-white' : ' text-green-500   '
                    }  `}
                />
                <div className='ml-2'> {message?.message?.name} </div>
              </div>
            </div>
          )
        case 'application/vnd.ms-excel':
          return (
            <div
              className={`flex items-center  ${color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
                }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            >
              <div
                className={`${color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                  } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileExcel
                  size={30}
                  className={` ${color ? 'text-white' : ' text-green-500   '
                    }  `}
                />
                <div className='ml-2'> {message?.message?.name} </div>
              </div>
            </div>
          )
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return (
            <div
              className={`flex items-center  ${color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
                } border p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            >
              <div
                className={`${color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                  } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileExcel
                  size={30}
                  className={` ${color ? 'text-white' : ' text-green-500   '
                    }  `}
                />
                <div className='ml-2'> {message?.message?.name} </div>
              </div>
            </div>
          )
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'docx':
        case 'doc':
          return (
            <div
              className={`flex items-center  ${color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
                }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            >
              <div
                className={`${color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                  } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileWord
                  size={30}
                  className={` ${color ? 'text-white' : ' text-green-500   '
                    }  `}
                />
                <div className=''> {message?.message?.name} </div>
              </div>
            </div>
          )
        case 'text/plain':
          return (
            <div
              className={`flex items-center  ${color
                  ? '  bg-[#F5F5F5] text-black '
                  : ' bg-everglade-600  text-white '
                }  p-2 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            >
              <div
                className={`${color
                    ? '  bg-everglade-600  text-white'
                    : ' bg-[#F5F5F5] text-black '
                  } flex flex-row items-center rounded-lg p-2`}
              >
                <FaFileAlt
                  size={30}
                  className={` ${color ? 'text-white' : ' text-green-500   '
                    }  `}
                />
                <div className=''> {message?.message?.name} </div>
              </div>
            </div>
          )
        default:
          if (type.startsWith('image/')) {
            ; <img
              src={message?.message?.url}
              alt='File'
              className='w-[200px] h-[200px] object-cover hover:cursor-pointer'
              onClick={(e) => {
                e.preventDefault()
                SaveFile(message?.message?.url, message?.message?.name)
              }}
            />
          } else {
            return
          }
          break
      }
    } else {
      if (message?.message?.ogMetaData != null) {
        return ogMetaDataFormat(message, color)
      } else {
        return messageFormat(message?.message?.message, color)
      }
    }
  }
  return (
    <div className='mt-5 mb-5'>
      {user !== message?.message?.sender?.userId ? (
        <>
          {Message.customType === 'VIDEO_CALL' ? (
            <div className='flex justify-center '>
              <div className='flex flex-row justify-evenly items-center bg-everglade-500  text-center h-15 px-10  w-[300px] rounded-2xl text-white'>
                <FaPhoneAlt />
                <div className='ml-2'>
                  {message?.message?.sender?.nickname} {message?.message?.message?.callStatus}
                  {formatDuration(JSON.parse(message?.message?.message))}
                </div>
              </div>
            </div>
          ) : (
            <>
              {messageData?.name && (
                <span className='flex flex-row italic  text-gray-400'>
                  <LuForward />
                  Forwarded{' '}
                  {message?.message?.sender?.nickname != messageData?.name
                    ? messageData?.name + "'s message"
                    : null}
                </span>
              )}
              <div className='flex  flex-row  gap-5 '>
                {channel.name === 'Admin' ? (
                  <Avatar
                    image={
                      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC-Is9vPh4AgDCULO33AmoIpSvXcaJ51VrRZQUDXxl0YjDDAq94jciUm7zt7SB4OD5UhA&usqp=CAU'
                    }
                  />
                ) : (
                  <Avatar
                    fname={message?.message?.sender?.nickname}
                    image={
                      message?.message?.sender?.plainProfileUrl !== null
                        ? message?.message?.sender?.plainProfileUrl
                        : null
                    }
                    width={'w-9 h-9'}
                    profile={true}
                  />
                )}
                <div className='flex flex-col'>
                  {channel.name === 'Admin' ? (
                    <p className=' italic  text-gray-400'>ConnectCare Team</p>
                  ) : (
                    <p className=' italic  text-gray-400'>
                      {message?.message?.sender?.nickname}
                    </p>
                  )}
                  {/* <div className='rounded-md max-w-[formatDuration550px]'>
            {
            messageData?.name && <span className='flex flex-row italic p-1 text-gray-400'>
              <LuForward />
               Forwarded {message?.message?.sender?.nickname!=(messageData?.name)?(messageData?.name+"'s message"):null}
            </span>
           } */}
                  <div className={`${message?.message?.sender?.userId.startsWith('PA')
                    ? ' bg-[#99422b] text-white'
                    : 'bg-[#F5F5F5] text-black '
                    }  cursor-pointer rounded-lg p-2`}>
                    {dislayFiles(
                      Message.customType ? null : message,
                      message?.message?.messageType,
                      message?.message?.type,
                      message?.message?.sender?.userId.startsWith('PA') ? null : "color"
                    )}
                    <div className={`${message?.message?.sender?.userId.startsWith('PA')
                      ? ' text-white '
                      : ' text-gray-500  dark:text-gray-400 '
                      } text-sm self-end`} >
                      <div className='flex flex-row gap-4'>
                        {messageData && dislayPredefinedData(true)}
                        <p className='flex flex-row items-end gap-4 ml-auto'>{formatMessageTime(message?.message?.createdAt)}</p>
                      </div>
                    </div>

                  </div>
                </div>
                {/* </div> */}
                <div className='relative'>
                  <Menu as={'div'} className={'inline-block text-left '}>
                    <Menu.Button>
                      {message?.message?.messageType !== 'file' &&
                        message?.message?.sender &&
                        message?.message?.sender?.userId !== '' &&
                        message?.message?.sender?.userId.startsWith('PA') && (
                          <div>
                            <HiDotsVertical />
                          </div>
                        )}
                    </Menu.Button>
                    <Menu.Items
                      className=' absolute  z-10 w-fit left-10 top-[-25px]  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                      style={{
                        zIndex: 5,
                      }}
                    >
                      <div className='px-1 py-1 flex flex-col '>
                        {message?.message?.messageType !== 'file' && (
                          <Menu.Item>
                            {({ active }) => (
                              <p
                                className={`${active && 'text-everglade-700  cursor-pointer'
                                  } p-2`}
                                onClick={() => {
                                  setMessage(message?.message)
                                  console.log(
                                    message?.message?.sender?.nickname
                                  )
                                  setMessageForwardedFrom(
                                    message?.message?.sender?.nickname
                                  )
                                }}
                              >
                                Forward
                              </p>
                            )}
                          </Menu.Item>
                        )}
                        {message?.message?.sender &&
                          message?.message?.sender?.userId !== '' &&
                          message?.message?.sender?.userId.startsWith('PA') && (
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={`${active &&
                                    'text-everglade-700  cursor-pointer'
                                    } p-2`}
                                  onClick={() => {
                                    addToMyChat(message)
                                  }}
                                >
                                  Assign
                                </p>
                              )}
                            </Menu.Item>
                          )}
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>
              </div>
            </>
          )}
        </>
      )
        : Message.customType === 'VIDEO_CALL' ? (
          <div className='flex justify-center '>
            <div className='flex flex-row justify-evenly items-center bg-everglade-500  text-center h-15 px-10  w-[300px] rounded-2xl text-white'>
              <FaPhoneAlt />
              <div className='ml-2'>
                {message?.message?.sender?.nickname}
                {formatDuration(JSON.parse(message?.message?.message))}
              </div>
            </div>
          </div>
        )
          : (
            <div className='flex justify-end'>
              <div className='flex flex-col'>
                {messageData?.name && (
                  <span className='flex flex-row italic  text-gray-400'>
                    <LuForward />
                    Forwarded{' '}
                    {message?.message?.sender?.nickname != messageData?.name
                      ? messageData?.name + "'s message"
                      : null}
                  </span>
                )}
                <div className='flex flex-row gap-5'>
                  <div className='relative self-end'>
                    <Menu as={'div'} className={'inline-block text-left pb-2'}>
                      <Menu.Button>
                        <div>
                          <HiDotsVertical />
                        </div>
                      </Menu.Button>
                      <Menu.Items
                        className=' absolute  z-10  right-[25px] top-[-30px]  w-fit mt-2  origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                        style={{
                          zIndex: 5,
                        }}
                      >
                        <div className='px-1 py-1 flex flex-col'>
                          {message?.message?.messageType !== 'file' && (
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={`${active && 'text-everglade-700  cursor-pointer'
                                    } p-2`}
                                  onClick={() => {
                                    setMessage(message?.message)
                                    setMessageForwardedFrom(
                                      message?.message?.sender?.nickname
                                    )
                                  }}
                                >
                                  Forward
                                </p>
                              )}
                            </Menu.Item>
                          )}
                          {message?.message?.sender &&
                            message?.message?.sender?.userId !== '' &&
                            message?.message?.sender?.userId.startsWith('PA') && (
                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={`${active && 'text-everglade-700  cursor-pointer'
                                      } p-2`}
                                    onClick={() => {
                                      addToMyChat(message)
                                    }}
                                  >
                                    Assign
                                  </p>
                                )}
                              </Menu.Item>
                            )}
                          {
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={`${active && 'text-everglade-700  cursor-pointer'
                                    } p-2`}
                                  onClick={() => {
                                    deleteMyChat(message.message)
                                  }}
                                >
                                  Delete
                                </p>
                              )}
                            </Menu.Item>
                          }
                        </div>
                      </Menu.Items>
                    </Menu>
                  </div>
                  <div className='bg-everglade-600 text-white cursor-pointer rounded-lg p-2'>
                    {dislayFiles(
                      message,
                      message?.message?.messageType,
                      message?.message?.type
                    )}
                    <div className='text-sm text-gray-300  dark:text-gray-300 self-end'>
                      <div className='flex flex-row gap-4'>
                        {messageData && dislayPredefinedData(false)}
                        <p className='flex flex-row items-end gap-4 ml-auto'>{formatMessageTime(message?.message?.createdAt)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='text-sm text-gray-500  dark:text-gray-400 self-end'>
                    <div className='flex flex-row items-center gap-4'>
                      {delivered && !read ? (
                        <MdOutlineCheck />
                      ) : (
                        <LiaCheckDoubleSolid
                          className={read && ' text-everglade-500'}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
    </div>
  )
}

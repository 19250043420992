import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import SendBirdCall from 'sendbird-calls';
import Archive from './Components/Archive';
import MiddleComponent from './Components/MiddleComponent';
import NotFound from './Components/NotFound';
import Passwords from './Components/Passwords';
import PatientDetail from './Components/PatientDetail';
import Sidebar from './Components/Sidebar';
import PatientList from './Components/patientlist';
import ChatCall from './Components/shared/calls';
import Chat from './Components/shared/chat';
import PrevChat from './Components/shared/chat/prev/prevChat';
import Navbar from './Components/shared/navbar';
import Login from './pages/Login';
import Profile from './pages/Profile';
import SignUp from './pages/SignUp';
import ChatPage from './pages/chatPage';
import ChatTeam from './pages/chatTeam';
import PasswordReset from './pages/passwordReset';
import PredefinedMessage from './pages/predefinedMessages';
import { toogle } from './redux/slices/sidebar';
import { SENDBIRD_INFO } from './utils/helpers/constant';
import ChangePassword from './pages/changepassword';
import './App.css'

function App() {
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.auth);
  const [windowSize, setWindowSize] = useState(false);
  const { open } = useSelector(state => state.sidebar);
  const userId = useSelector(state => state.auth);
  const [user, setUser] = useState(null);
  const [initialized,setInitialized]=useState(false)
  const token = userId?.userInfo?.doctor_login?.sendbird_user_id;
  const appId =SENDBIRD_INFO.appId;
  const dispatch = useDispatch();
  const params = {
    appId: SENDBIRD_INFO.appId,
    modules: [
      new GroupChannelModule()
    ]
  }


  useEffect(()=>{
    if (userId && userId?.userInfo?.doctor_login?.sendbird_user_id && userId?.userInfo?.doctor_login?.sendbird_user_id !== "" && userId?.userInfo?.doctor_login?.sendbird_user_id !== null) {
      setUser(userId?.userInfo?.doctor_login?.sendbird_user_id);

      try {
        SendbirdChat.init(params).connect( userId?.userInfo?.doctor_login?.sendbird_user_id).catch(err=>{console.log("errsc",err)});
      } catch (error) {
         console.log("mee",error)
      }
    }
  },[userId])

  useEffect(() => {
    try {
    SendBirdCall.init(SENDBIRD_INFO.appId);
    if (userId && userId?.userInfo?.doctor_login?.sendbird_user_id && userId?.userInfo?.doctor_login?.sendbird_user_id !== "" && userId?.userInfo?.doctor_login?.sendbird_user_id !== null) {
      setUser(userId?.userInfo?.doctor_login?.sendbird_user_id);

      SendBirdCall.authenticate({ userId: userId?.userInfo?.doctor_login?.sendbird_user_id }, (result, error) => {
        if (error) {
          console.log({error})
          setInitialized(false)
          // Handle authentication failure.
        } else {
          console.log("connected")
          setInitialized(true)
          // The user has been successfully authenticated and is connected to the Sendbird server.
          //..
        }
      });
    }
    } catch (error) {
      console.log({error})
    }
    

  }, [userId]);
  useEffect(() => {
    if(initialized){
      try {
        SendBirdCall.connectWebSocket()
        .then(function () {
          console.log("connected success")
  
        }).catch(error => {
          console.log('sendBird connection errors',error);
        })
       } catch (error) {
         console.log({error})
       }
    } 
  }, [initialized])

  useEffect(() => {
    if (windowSize) {
      dispatch(toogle(true));
    }
  }, [windowSize])

  const myColorSet = {
    '--sendbird-light-primary-500': '#4f9d4a',
    '--sendbird-light-primary-400': '#73ba6e',
    '--sendbird-light-primary-300': '#a4d5a0',
    '--sendbird-light-primary-200': '#cde8ca',
    '--sendbird-light-primary-100': '#e6f4e4',
};
  return (
      
        userToken ? (

          <SendbirdProvider
          appId={appId} userId={token} colorSet={myColorSet}>

          <div className=' flex bg-[#F6F8FB] h-full overflow-y-auto '>
            <Sidebar />
            <div className='w-full flex-1'>
              <div className="right-0 w-full h-14 main-content bg-white " style={{ position: 'fixed', width: `${open ? 'calc(100% - 300px)' : ''}` }}>
                <Navbar />
              </div>
              <div className={`${open ? 'hidden md:flex' : 'flex'}  main-content overflow-y-auto px-4 w-full right-0 flex flex-col flex-1 overflow-auto h-full  p-2 pb-4 mt-[60px]`} style={{ position: 'fixed', width: `${open ? 'calc(100% - 300px)' : ''}`,zIndex:-3 }}>
                <Routes>
                  <Route exact path='/dashboard' element={<MiddleComponent />}></Route>
                  <Route exact path='/passwords' element={<Passwords />}></Route>
                  <Route exact path='/chat-call' element={<ChatCall />}></Route>
                  <Route exact path='/archives' element={<Archive />}></Route>
                  <Route exact path='/patientList' element={<PatientList />}></Route>
                  <Route exact path='/chat' element={<ChatPage />}></Route>
                  <Route exact path='/chatTeam/:type' element={<ChatTeam />}></Route>
                  <Route exact path='/chats' element={<Chat />}></Route>
                  <Route exact path='/pro-chat' element={<PrevChat />}></Route>
                  <Route exact path='/predefined' element={<PredefinedMessage />}></Route>
                  <Route exact path='/patientDetails/:patientId' element={<PatientDetail />}></Route>
                  <Route exact path='/profile' element={<Profile />}></Route>
                  <Route exact path='/change-password' element={<ChangePassword />}></Route>
                  <Route exact path='*' element={<MiddleComponent />}></Route> 

                </Routes>
                <section className='h-5'></section>
              </div>
            </div>
            {/* <RightSection /> */}
          </div>
          </SendbirdProvider>

        ) :
          (
            <div className='h-full'><Routes>
              <Route exact path='/' element={<Login />}></Route>
              <Route exact path='/login' element={<Login />}></Route>
              <Route exact path='/forgetpassword' element={<SignUp />}></Route>
              <Route path="/resetpassword/:token" element={<PasswordReset />} />
              <Route exact path='*' element={<NotFound />}></Route> 
            </Routes>
            </div>
          )      

  );
}

export default App;

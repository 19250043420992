import { useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { unToogleUplaod } from '../../../redux/slices/chat'
import Modal from '../modal'
import { DisplayProfile } from './displayProfile'
import { toastModal } from '../../../utils/helpers/methods'

export const CustomDropZone = (props) => {
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const { open } = useSelector(state => state.sidebar);

  const dispatch = useDispatch()

  const { isUploading } = useSelector((state) => state.chatPre)
  const dropzoneRef = useRef(null)

  const onDrop = (files) => {
    const acceptedTypes = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'text/plain',
    ]
    if (files.length > 0) {
      const selectedFile = files[0]
      if (selectedFile.size > 10485760) {
        toastModal('File size exceeds 10MB.')
        return
      }

      if (!acceptedTypes.includes(selectedFile.type)) {
        toastModal('File type is not supported.')
        return
      } else {
        setFileName(selectedFile.name)
        setFile(selectedFile)
        const reader = new FileReader()
        reader.onload = (e) => {
          const base64Data = e.target.result
          const urlWithFileName = `${base64Data}#${encodeURIComponent(
            selectedFile.name
          )}`
          setImageUrl(urlWithFileName)
          setOpenModal(true)
          setIsDragging(false)
        }
        reader.readAsDataURL(selectedFile)
      }
    }
  }

  useEffect(() => {
    if (isUploading) {
      dropzoneRef.current.open()
    }
  }, [isUploading])

  useEffect(() => {
    return () => {
      dispatch(unToogleUplaod())
    }
    //eslint-disable-next-line
  }, [])
  const cancelDownloading = () => {
    dispatch(unToogleUplaod())
  }

  return (
    <>
      <Dropzone
        multiple={false}
        onDrop={onDrop}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        noClick={!isUploading}
        noKeyboard={!isUploading}
        ref={dropzoneRef}
        onFileDialogCancel={cancelDownloading}
        accept='.pdf, image/*, .txt'
      >
        {({ getRootProps, getInputProps }) => (
          <section
            className={`relative border-2 w-[82%] h-[90vh] my-2 mt-20 ${
              isDragging ? 'border-everglade-600 opacity-20' : ''
            }${
              open ? 'chat-view' : ''
            }`}
          >
            <div
              {...getRootProps({
                className: 'w-full',
              })}
            >
              <input {...getInputProps()} />
              <div className=''>{props.children}</div>
            </div>
          </section>
        )}
      </Dropzone>
      {openModal && (
        <Modal
          close={() => {
            dispatch(unToogleUplaod())
            setOpenModal(false)
          }}
          className={'w-[900px]'}
        >
          <DisplayProfile
            imageUrl={imageUrl}
            fileName={fileName}
            file={file}
            channel={props.channel}
            onClose={() => {
              setOpenModal(false)
              dispatch(unToogleUplaod())
            }}
          />
        </Modal>
      )}
    </>
  )
}

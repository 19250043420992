import { Icon } from '@iconify/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import mymdimg from '../images/loginimg.jpeg'
import { logout } from '../redux/slices/authenticate'
import { toogle } from '../redux/slices/sidebar'
import { getToken, toastError } from '../utils/helpers/methods'
import Navigator from './Navigator'
import IconMD from "../images/icon.png";
import { AiOutlineClose } from 'react-icons/ai';
import '../App.css'
const Sidebar = () => {
  const {  userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {open}=useSelector(state=>state.sidebar);
  const [inputs, setInputs] = useState({
    credentialsInvalid: false
  });
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getToken();
  }, []);
  useEffect(()=>{
    if(inputs.credentialsInvalid){
      localStorage.clear()
      navigate('/login')
      toastError("Unauthorized access. Please log in or check your credentials and try again. logging out...")
      dispatch(logout());
    }
  },[inputs])
  const handleToggle = (e) => {
    dispatch(toogle()) 
  }
  const handleTabClick = () => {
    localStorage.clear()
    navigate('/login')
    dispatch(logout());

  };
  axios.interceptors.response.use(
    response => {
      return response
    },
    function (error) {

      if (error?.response?.status === 401) {
        setInputs({ ...inputs, credentialsInvalid: true });
        
      }
      return Promise.reject(error)
    }
  )
  const filteredMenuItems = [
    { path: '/dashboard', text: 'Home' ,icon:'tabler:home-2'},
    { path: '/patientList', text: 'Patient List',icon:'material-symbols:patient-list-outline'},
    { path: '/chat', text: 'Chat', icon:'humbleicons:chat'},
    { path: '/predefined', text: 'Predefined Message',icon:'fluent:mail-template-24-regular' },
  ]
const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};

const handleSearchButtonClick = () => {
  const option = {
    state:searchQuery
  };
  if(searchQuery){
  navigate('/patientList',option);
  setSearchQuery('');
  }
};
  return (
    <>
    {open && <div className={`lg:hidden fixed w-full h-full bg-black opacity-50 z-[1048px] `} onClick={()=>dispatch(toogle())} style={{zIndex:'1024'}}></div>}
    <div className={`${open?'w-[300px]':'w-0'} sidebar-content bg-everglade-600 fixed  lg:relative z-[1000px] left-0  top-0 bottom-0 overflow-y-none  overflow-hidden  shadow-md`} style={{position:'fixed',zIndex:'1048'}}>
      <div className='w-full rounded-br-[70px] relative mobile-header'>
        <div className=' items-center p-2 bg-white'onClick={()=>navigate('/dashboard')}>
          <h1 className='flex flex-row gap-2 font-bold tracking-[2px] text-[#fff] text-[23px] justify-center'>
          <img src={IconMD} className='w-[15%]' alt='mymd'/>
            <span className='text-everglade-600 font-bold'>Care</span>
            <span className='text-orange-900 font-bold'>Web</span>
          </h1>
        </div>
        <AiOutlineClose
        size={24}
        className='cursor-pointer text-black'
        onClick={handleToggle}
        />
      </div>
        <div className="w-full ">
        {filteredMenuItems.map((item) => (
          <Link to={item.path} key={item.path}>
            <Navigator icon={item.icon} item={item} pathname={location.pathname} />
          </Link>
        ))}
      </div>     
    </div>
    </>
  )
}

export default Sidebar
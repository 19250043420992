import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import { useEffect, useState } from 'react';
import { BiCommentDetail } from "react-icons/bi";
import { FaClinicMedical } from "react-icons/fa";
import { FiLogOut, FiUser, FiUsers } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPatientList } from "../../../redux/actions/passActions";
import { createChannel } from "../../../utils/helpers/methods";


const ChatFloatingStatus = ({ onSelected }) => {
  const { userToken, userInfo, doctorDetails } = useSelector((state) => state.auth);
  const store = useSendbirdStateContext();
  const sdk = store?.stores.sdkStore.sdk;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: "",
      patient_tag: "",
      search_key: ""
    }
    dispatch(getPatientList(obj));
  }, []);

  return (
    <>
      <section className="min-w-[300px] flex  flex-col min-h-[320px] justify-end cursor-pointer items-end space-y-3 fixed pr-10 pb-10 right-0 bottom-0">
        {show && <section className="w-full pr-4 flex flex-col space-y-4 pb-8 items-end justify-end min-h-[300px] transition ">
          <section className="flex items-center space-x-4">
            <span className="text-everglade-800">Offline</span>
            <section onClick={() => onSelected("offline")} className="h-14 w-14 rounded-full border-2 border-everglade-600 flex items-center justify-center"><FiLogOut className="text-everglade-600" size={20} /></section>
          </section>
         
        </section>
        }
        <section className=" flex flex-col items-center space-y-2 justify-end">
          <section onClick={() => setShow(!show)} className="h-14 w-14 rounded-full border-2 bg-everglade-900 bg-gradient-to-r from-everglade-400 to-everglade-800  hover:from-everglade-800 hover:to-everglade-500 flex items-center justify-center">{show ? <MdClose className="text-white" size={20}/> : <FiUser className="text-white"/>}</section>
          <p className="text-everglade-800">Change Status</p>
        </section>
      </section>
    </>
  );
}
export default ChatFloatingStatus;
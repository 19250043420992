import { createAsyncThunk } from "@reduxjs/toolkit";
import { addPredefinedMessages, deletePredefinedMessages, saveCallDurationMethod, saveChannel, updatePredefinedMessages,retrieveCallHistory, updateLastMessage, sendBirdChatAssign, assignChatNormal } from "../../utils/services/chat.service";

export const PostPreMessageRequest = createAsyncThunk(
    'post/pre-message',
    async ({data,success,failure}, { rejectWithValue}) => {
        try {
            const result = await addPredefinedMessages(data);
            if(result.data.result!=='failed'){
                success();
            }
            else{
                failure(result.data.message);
            }
            return result;
        } catch (error) {
            if(error?.response?.data?.error?.key_word[0]){
                return failure(error.response.data.error.key_word[0]);
            }
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const UpdatePreMessageRequest = createAsyncThunk(
    'post/update-message',
    async ({data,success,failure}, { rejectWithValue}) => {
        try {
            const result = await updatePredefinedMessages(data);
            if(result.data.result!=='failed'){
                success();
            }
            else{
                failure(result.data.message);
            }
            return result;
        } catch (error) {
            if(error?.response?.data?.error?.key_word[0]){
                return failure(error.response.data.error.key_word[0]);
            }            
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const DeletePreMessageRequest = createAsyncThunk(
    'post/delete-message',
    async ({data,success,failure}, { rejectWithValue}) => {
        try {
            const result = await deletePredefinedMessages(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
export const SaveChannelMessage = createAsyncThunk(
    'post/save-channel',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await saveChannel(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const UpdateChannelLatestMessage= createAsyncThunk(
    'update_last_message_time',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await updateLastMessage(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const saveCallDuration = createAsyncThunk(
    'post/save-call',
    async ({data,success}, { rejectWithValue}) => {
        try {
            const result = await saveCallDurationMethod(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const getChatHistory = createAsyncThunk(
    'get/call-history',
    async ({id}, { rejectWithValue}) => {
        try {
            const result = await retrieveCallHistory(id);
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)
export const saveChatAssign= createAsyncThunk(
    'post/chatAssign',
    async ({data,success}, { rejectWithValue}) => {
        console.log('savechatAssign')
        try {
            const result = await sendBirdChatAssign(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)


export const SaveAssignChatNormal= createAsyncThunk(
    'post/chatAssignNormal',
    async ({data,success}, { rejectWithValue}) => {
        console.log('asssignchatnormal')
        try {
            const result = await assignChatNormal(data);
            success();
            return result;
        } catch (error) {
            if(error.response && error.response.data.message){
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)


export const SENDBIRD_INFO = {
  appId:'18C5DFF5-E69F-4397-82DF-3DD9EC8BF18E', //prod,
  sendBirdToken:'f235fc53bd95780fd9f73ba812b1df980984ec2f' //prod
  // appId : 'AABF7B1D-04B9-44DE-8517-094D0DA6FCE9',//local
  // sendBirdToken:'1c814f4605a7fff9ec8774c60c5f18efb0e45a35'//local
  // appId : '0C280661-DEF1-469E-9E68-60AAF54BBF25',//local
  // sendBirdToken:'703420effa82872582a6df8812cd820a65acd755'//local
  };


// const one = {
//   name:"Hello Test",
//   id:"PA16"
// }  
// const two = {
//   name:"jeremy",
//   id:"PR1"
// }  
// const thre = {
//   name:"first users",
//   id:"PA10828"
// }  
// const four = {
//   name:"Anastasia Benson, D.O",
//   id:"PR11"
// }  
// const five = {
//   name:"Annette Marin",
//   id:"PR10"
// } 
//  const six = {
//   name:"mellisa",
//   id:"PR3"
// }  
// const seven ={
//   name:"nikolas",
//   id:"PA14"
// }
 
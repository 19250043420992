import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messagesCount: [],
  allMessage:[],
  assignedMessage:[]
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getMessageCount: (state, { payload }) => {
      state.messagesCount = payload;
    },
    getAllMessage:(state,{payload})=>{
      state.allMessage=payload
    },
    getAssignedMessage:(state,{payload})=>{
      state.assignedMessage=payload
    }
  }
});

export const { getMessageCount,getAllMessage,getAssignedMessage } = notificationSlice.actions;
export default notificationSlice.reducer;


import { Avatar, Tooltip } from 'flowbite-react';
import { FaRegFilePdf, FaRegFileWord } from 'react-icons/fa';


const getFileIcon = (data,url) => {
  const fileExtension = getFileExtension(data?.filename);
  if (fileExtension === 'pdf') {
    return <div className="w-10 pt-1">
      <Tooltip content={data?.filename}><FaRegFilePdf color="#4f9d4a" size={34}/></Tooltip></div>;
  }
  else if (fileExtension === 'doc' || fileExtension === 'docx') {
    return <div className="w-10 pt-1"><Tooltip content={data?.filename}><FaRegFileWord color="#4f9d4a"size={34} /></Tooltip></div>;
  }
  else if (fileExtension === "jpeg" || fileExtension === "jpg" || fileExtension === "png") {
    return <div className="w-10 m-1">
      {/* <img src={url+'/'+ data?.filename} alt="Preview" /> */}
      <Tooltip content={data?.filename}><Avatar img={url+'/'+ data?.filename} className='relative img-block'/></Tooltip>
</div>;
  }
}

const getFileExtension = (data) => {
  if(data){
  return data.split('.').pop().toLowerCase(); 
}
};

export { getFileIcon, getFileExtension };

import * as http from "./api"

export const addPredefinedMessages=async (data)=>{
  return await http.POST("doctortextreplacement",data);
}
export const updatePredefinedMessages=async (data)=>{
    return await http.POST("doctortextupdate",data);
}
export const deletePredefinedMessages=async (data)=>{
    return await http.POST("doctortextdelete",data);
}

export const getAllPredefinedMessages=async ()=>{
    return await http.GET("/api/auth/user");
  }

  export const saveChannel=async (data)=>{
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    return await http.POST("sendbirdchanneldetails",data,{headers});
}
export const updateLastMessage=async (data)=>{
    return await http.POST("update_last_message_time",data);
}
export const saveCallDurationMethod=async (data)=>{
    return await http.POST("sbcalllog",data);
}

export const retrieveCallHistory=async (id)=>{
    return await http.GET(`doctorcalldetails/${id}`);
}

export const sendBirdChatAssign=async (data)=>{
    return await http.POST("sendbirdchatassign",data);
}

export const assignChatNormal=async (data)=>{
    return await http.POST("asssignchatnormal",data);
}